import React from "react";
import { Link } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../constants/internalRoutes";
import { useSelector } from "react-redux";
import { translations } from "../../../translations/texts";

const FooterLinks = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <div
      className="footer-links-outer-container"
      data-testid="container-footer-links"
      id="footerLinks"
    >
      <div className="footer-parent-link-container ">
        {/* <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.HELP_CENTER} className="footer-link" id="helpButton">
            {translations[selectedLanguage].helpCenter.title}
          </Link>
        </div> */}
        <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.JOBS} className="footer-link" id="jobsButton">
            {translations[selectedLanguage].jobs.title}
          </Link>
        </div>
        <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.FAQ} className="footer-link" id="faqButton">
            {translations[selectedLanguage].faqPage.title}
          </Link>
        </div>
        <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.TERMS} className="footer-link" id="termsButton">
            {translations[selectedLanguage].terms.title}
          </Link>
        </div>
        <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.PRIVACY} className="footer-link" id="privacyButton">
            {translations[selectedLanguage].privacy.title}
          </Link>
        </div>
        {/* <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.LEGAL_NOTICES} className="footer-link" id="legalNoticesButton">
            {translations[selectedLanguage].legalNotices.title}
          </Link>
        </div> */}
        <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.CONTACT} className="footer-link" id="contactButton">
            {translations[selectedLanguage].contact.title}
          </Link>
        </div>
        {/* <div className="footer-single-link-container">
          <Link to={INTERNAL_ROUTES.REDEEM} className="footer-link" id="redeemButton">
            {translations[selectedLanguage].common.redeem}
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default FooterLinks;
