

export const CookiesBanner = {
	title: "Cookies Preferences",
	intro: "By using this website, you agree to our use of cookies and similar technologies used for the website to function properly, opting out may cause the website to not function as intended.",
	functionalCookies: "Functional Cookies",
	rejectAll: "Reject",
	saveAll: "Accept"
};

