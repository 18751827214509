import React from "react";
import "./SubPresentation.scss";
import { useSelector } from "react-redux";
import { translations } from "../../../translations/texts";

const SubPresentation = () => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	return (
		<section data-testid="container-presentation" className="presentation">
			<h1 className="presentation-title">{translations[selectedLanguage].presentation.title}</h1>
			<h2>{translations[selectedLanguage].presentation.subtitle}</h2>
			<a
				id="takeTheLeapButton"
				href="https://nomads.live/vr/index.html?videoLink=https://dggouis4luqok.cloudfront.net/converted-video-assets/nomads_trailer_june2021/Long_teaser_finale_SM.m3u8"
				target="_blank"
				rel="noopener noreferrer"
			>
				{translations[selectedLanguage].presentation.button}
			</a>
			<p>{translations[selectedLanguage].presentation.content}</p>
		</section>
	);
};

export default SubPresentation;
