import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../constants/internalRoutes";
import { LANG_ABREVIATION } from "../../../constants/language";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/reducers/language";
import { translations } from "../../../translations/texts";

import { FaLanguage, FaStore, FaShoppingCart, FaHouseUser, FaVrCardboard } from "react-icons/fa";
import CONFIG from "../../../config";

const SubNavbarLinks = ({ closeMenu, cartItems, isLoggedIn, openSidebar, user }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	const showAlert = () => {
		alert(translations[selectedLanguage].common.popupWindow); // This will show the alert
	};

	const handleLanguageChange = (language) => {
		localStorage.setItem("lang", LANG_ABREVIATION.language);
		dispatch(setLanguage(language));
	};

	const renderCartBtn = () => {
		return (
			<Link className="navbar-item" onClick={() => closeMenu()} to={INTERNAL_ROUTES.USER.CART} id="cartButton">
				<FaShoppingCart /> {`${translations[selectedLanguage].common.cart} (${cartItems.length})`}
			</Link>
		);
	};

	const renderLanguageBtn = () => {
		return (
			<ul className="navbar-item lang-navbar-item" value="" defaultValue>
				{CONFIG.enableLanguageSwitch && (
					<li className="wrap-lang-dropdown" value="" defaultValue id="languageSelection">
						<FaLanguage />
						{selectedLanguage.toUpperCase()}
						<ul className="lang-dropdown">
							<li className="store lang-li" onClick={() => handleLanguageChange(LANG_ABREVIATION.EN)} id="englishSelection">
								{LANG_ABREVIATION.EN}
							</li>
							<li className="lang-li" onClick={() => handleLanguageChange(LANG_ABREVIATION.FR)} id="frenchSelection">
								{LANG_ABREVIATION.FR}
							</li>
						</ul>
					</li>
				)}
			</ul>
		);
	};

	return (
		<section data-testid="container-navbar-links" className="navbar-link-container">
			{renderLanguageBtn()}
			{/* <div
				className="navbar-item navbar-store-button"
				onClick={() => {
					closeMenu();
					navigate(INTERNAL_ROUTES.WEBSTORE);
				}}
				id="webstoreButton"
			>
				<FaStore />
				{translations[selectedLanguage].common.store}
			</div> */}
			{/* {isLoggedIn ? renderCartBtn() : null} */}
			{!isLoggedIn && (
				<>
					<div className="wrap-navbar-button">
						<p onClick={showAlert} className="navbar-item navbar-button">
							{translations[selectedLanguage].common.loginLink}
						</p>
						{/* <b> */}
						{/* <FaVrCardboard />
							{translations[selectedLanguage].common.loginLink}
						{/* </Link> */}
						{/* </b> */}
					</div>
					<div className="wrap-navbar-button">
						<p onClick={showAlert} className="navbar-item navbar-button">
							{translations[selectedLanguage].common.signUpLink}
						</p>
					</div>
				</>
			)}
		</section>
	);
};

export default SubNavbarLinks;
