import "./CookiesBanner.scss";
import { useState } from "react";
import { Switch } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { addFunctionalCookies, closeWindow } from "../../store/reducers/CookiesManager.js";
import { ASSETS_PATH } from "../../constants/constants.js";
import { deleteCookie, getToken } from "../../utilities/cookie.js"
import { COOKIES } from "../../constants/cookies.js";
import { translations } from "../../translations/texts.js";
import { useSelector } from "react-redux";
import { setGDPRCookie } from "../../utilities/cookie.js";


const CookiesBanner = () => {
	const [checked, setChecked] = useState(true);
    const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	const dispatch = useDispatch();

	const handleChange = (event) => {
		setChecked(!checked);
	};

	const rejectAll = () => {
        deleteCookie(COOKIES.TOKEN, getToken());
        dispatch(closeWindow());
        
	};

	const saveSettings = () => {
		if (checked) {
			dispatch(addFunctionalCookies());
		} else if (!checked) {
            deleteCookie(COOKIES.TOKEN, getToken());
        }
        setGDPRCookie("gdprConsent", "true", 30, false);
		dispatch(closeWindow());
	};

	return (
			<div className="window" id="cookiesBanner">
				<img className="window-logo" src={ASSETS_PATH.NOMADS_LOGOS.TEXT_AND_LOGO} alt="Logo" />
                <div className="line"></div>
                <p className="title">{translations[selectedLanguage].CookiesBanner.title}</p>
				<p className="intro">{translations[selectedLanguage].CookiesBanner.intro}</p>
				{/* <div className="switch-container">
					<p className="title-cookies">{translations[selectedLanguage].CookiesBanner.functionalCookies}</p>
					<Switch checked={checked} onClick={handleChange} />
				</div> */}
				{/* Add more switches if needed */}

				<div className="buttons-container">
					<button onClick={rejectAll} className="save-buttons" id="rejectAll">{translations[selectedLanguage].CookiesBanner.rejectAll}</button>
					<button onClick={saveSettings} className="save-buttons" id="save-settings">{translations[selectedLanguage].CookiesBanner.saveAll}</button>
				</div>
			</div>
	);
};

export default CookiesBanner;
