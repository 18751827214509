import React from "react";
let index = 0;

export const faqPage = {
  title: "FAQ",
  questionAndAnswers: {
    [index++]: {
      question: <h2>Qu'est-ce que la réalité virtuelle (RV) ?</h2>,
      answer: (
        <p>
          La réalité virtuelle vous permet de découvrir de nouveaux mondes. En plaçant votre casque de réalité virtuelle sur votre tête et en lançant une expérience NOMADS, vous serez immergé dans un nouvel endroit, que ce soit un concert, un stade lors d'un match, au cœur d'un volcan, en mer entouré de requins, dans le ciel en parachute - tout est possible. Vous le verrez comme si vous y étiez, vivant l'expérience.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Je ne suis pas un gamer, la RV est-elle faite pour moi ?</h2>,
      answer: (
        <p>
          La réalité virtuelle n'est pas uniquement destinée aux jeux vidéo. Nos expériences NOMADS sont adaptées à tous les types de public. Que vous souhaitiez vous détendre lors d'une promenade en Italie ou ressentir une montée d'adrénaline lors d'un saut en chute libre, vous trouverez certainement des expériences qui correspondent à vos besoins. Si vous avez besoin d'aide, regardez notre tutoriel vidéo disponible ici (Lien vers la vidéo) ou contactez-nous pour toute assistance technique à support@nomads.live.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Comment puis-je regarder mon expérience RV ?</h2>,
      answer: (
        <p>
          Pour regarder une expérience RV de NOMADS, il vous suffit d'une connexion Internet et d'un accès à un navigateur. Cependant, pour profiter pleinement de nos expériences de réalité virtuelle, vous aurez besoin d'un casque de réalité virtuelle ou d'un smartphone et d'un cardboard. Tout type de casque fonctionne : autonome (comme l'Oculus Go), connecté à un ordinateur (comme le HTC Vive) ou Cardboard. Chaque expérience est également disponible en version 360º si vous préférez la regarder directement depuis votre navigateur sur votre ordinateur ou votre téléphone portable. Cependant, l'immersion sera complète uniquement en utilisant un casque. Cardboard est la solution la plus abordable et vous pouvez en obtenir un directement sur la boutique NOMADS : [URL BOUTIQUE CARDBOARD].
        </p>
      ),
    },
    [index++]: {
      question: <h2>Puis-je utiliser n'importe quel navigateur pour naviguer sur NOMADS ?</h2>,
      answer: (
        <p>
          Afin de vous offrir la meilleure expérience possible, NOMADS utilise les dernières technologies disponibles. Malheureusement, Internet Explorer ne prend pas encore en charge toutes les technologies de NOMADS, c'est pourquoi nous avons désactivé l'accès à NOMADS depuis ce navigateur. NOMADS fonctionne sur la plupart des autres navigateurs. Nous vous recommandons vivement d'utiliser Chrome, Firefox ou Safari pour profiter de toutes nos fonctionnalités. Veuillez également vous assurer que votre navigateur est à jour et que vous utilisez la dernière version.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Quels casques VR sont compatibles avec NOMADS ?</h2>,
      answer: (
        <p>
          NOMADS est une plateforme web de réalité virtuelle. Cela signifie que vous pouvez profiter de NOMADS depuis votre ordinateur si vous le souhaitez. Cependant, vous bénéficierez vraiment de la meilleure expérience possible en utilisant un appareil immersif. NOMADS est compatible avec tous les casques VR ou appareils mobiles, tant qu'un navigateur est accessible : smartphones, Oculus Go, Rift, HTC Vive, Google DayDream, et plus encore.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Combien coûte une expérience ?</h2>,
      answer: (
        <p>
          Chaque expérience est unique et a son propre prix. Pour vous offrir le meilleur service possible, certaines expériences sont composées de plusieurs épisodes. Dans ce cas, vous pouvez acheter chaque expérience séparément, ou acheter le package complet à tarif réduit. Bien que le contenu et la durée puissent varier d'une expérience à l'autre, toutes les expériences NOMADS partagent la même qualité exceptionnelle.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Puis-je regarder les expériences sans casque ?</h2>,
      answer: (
        <>
          <p>
            Toutes nos expériences sont disponibles en RV, ainsi que des versions 360º. Vous pouvez les regarder à tout moment depuis votre ordinateur en utilisant votre navigateur préféré (Chrome, Firefox, IE, Opera, etc.) ou depuis votre téléphone en utilisant n'importe quel navigateur disponible (Chrome, Safari, etc.).
          </p>
          <h3>Précautions</h3>
          <p>
            Afin de profiter pleinement des expériences NOMADS, nous vous recommandons vivement de vous installer confortablement, de préférence dans une chaise pivotante. Cela vous permettra de naviguer dans l'environnement 360º en toute sécurité. Alternativement, vous pouvez profiter de nos expériences dans un autre type de chaise ou canapé. Certaines expériences peuvent être déstabilisantes, surtout lorsque vous essayez la réalité virtuelle pour la première fois. C'est pourquoi la position assise est la plus recommandée. Si vous sentez que vous perdez l'équilibre, n'hésitez pas à retirer votre casque. Parfois, quelques secondes suffisent pour retrouver votre équilibre et replonger dans l'expérience !
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>Comment utiliser la commande vocale ?</h2>,
      answer: (
        <>
          <p>
            Pour utiliser le contrôle vocal, vous devez accepter l'utilisation de votre microphone. Si vous avez refusé l'accès à votre microphone, vous pouvez réactiver cette fonctionnalité à tout moment en autorisant l'accès à nouveau.
          </p>
          <h3>Pour Chrome</h3>
          <ol>
            <li>Ouvrez le menu</li>
            <li>Sélectionnez « Paramètres »</li>
            <li>Cliquez sur « Afficher les paramètres avancés »</li>
            <li>Dans la section « Confidentialité », cliquez sur « Paramètres de contenu »</li>
            <li>Dans la section « Médias », cliquez sur « Microphone »</li>
            <li>
              Trouvez NOMADS dans la section « Bloquer » et cliquez sur la « Corbeille »
              pour le supprimer
            </li>
            <li>Retournez sur NOMADS.live</li>
            <li>Cliquez sur « Autoriser » lorsqu'on vous le demande</li>
          </ol>
          <h3>Pour Firefox</h3>
          <ol>
            <li>Ouvrez le menu</li>
            <li>Sélectionnez Paramètres</li>
            <li>Cliquez sur la section « Vie privée et sécurité »</li>
            <li>Allez dans la section « Permissions »</li>
            <li>Allez dans « Microphone » et cliquez sur « Paramètres »</li>
            <li>Trouvez NOMADS et ajustez les paramètres en conséquence</li>
            <li>Retournez sur NOMADS.live</li>
            <li>Cliquez sur « Autoriser » lorsqu'on vous le demande</li>
          </ol>
          <h3>Pour Internet Explorer</h3>
          <p>Internet Explorer ne prend pas en charge la commande vocale</p>
          <h3>Pour Safari</h3>
          <ol>
            <li>Cliquez sur « Safari » dans le menu du haut</li>
            <li>Cliquez sur « Préférences »</li>
            <li>Cliquez sur « Sites web »</li>
            <li>Cliquez sur « Microphone »</li>
            <li>
              Trouvez NOMADS.live et ajustez les paramètres en conséquence (Demander ou
              Autoriser)
            </li>
            <li>Cliquez sur « Autoriser » lorsqu'on vous le demande</li>
          </ol>
        </>
      ),
    },
    [index++]: {
      question: <h2>J'ai acheté une expérience, où puis-je la trouver ?</h2>,
      answer: (
        <>
          <p>
            Si vous avez acheté une expérience et que vous avez encore des crédits de visionnage valides,
            vous pouvez les retrouver dans le menu « Mes Expériences ».
          </p>
          <p>
            NB : Si vous avez acheté une expérience mais que vous avez déjà utilisé tous
            vos crédits de visionnage, elle ne sera plus disponible dans l'interface "Mes Expériences". 
            Vous devrez l'acheter à nouveau pour la retrouver dans cette interface.
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>Comment commencer le tutoriel ?</h2>,
      answer: (
        <p>
          Le tutoriel commence automatiquement au démarrage, mais vous pouvez choisir à tout moment de le passer.
          Si vous souhaitez le réactiver plus tard, connectez-vous simplement à votre compte, cliquez sur "Paramètres" puis sur "Tutoriel".
          Il redémarrera automatiquement.
        </p>
      ),
    },
    [index++]: {
      question: <h2>J'ai acheté la mauvaise expérience, comment annuler mon achat ?</h2>,
      answer: (
        <>
          <p>
            Si vous avez acheté la mauvaise expérience par erreur, vous pouvez annuler
            votre achat dans les 14 jours pour les achats que vous n'avez pas commencés
            à diffuser ou à télécharger. Après 14 jours, ou une fois que vous avez commencé
            à diffuser ou à télécharger votre expérience NOMADS, votre achat devient
            définitif.
          </p>
          <p>
            Pour annuler votre achat, vous devez nous contacter par e-mail à
            support@nomads.live et mentionner votre nom complet, votre adresse e-mail
            associée au compte sur lequel vous avez effectué l'achat, ainsi que le
            titre de l'expérience que vous souhaitez annuler. Après vérification, le
            remboursement sera effectué via le même moyen de paiement utilisé pour l'achat initial.
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>Y a-t-il une restriction d'âge ?</h2>,
      answer: (
        <p>
          Il n'y a pas de restriction d'âge. Cependant, les casques de réalité virtuelle
          ne sont généralement pas recommandés pour les enfants de moins de 13 ans. La plupart
          de notre contenu est adapté à tous les publics, mais vous devez avoir 18 ans,
          (ou l'âge de la majorité dans votre province, territoire ou pays) pour effectuer un achat
          sur la plateforme NOMADS. Les mineurs peuvent uniquement utiliser le service sous la
          supervision d'un adulte.
        </p>
      ),
    },
    [index++]: {
      question: <h2>Comment fonctionne le système de paiement ?</h2>,
      answer: (
        <>
          <p>
            NOMADS accepte les principales cartes de crédit et cartes prépayées (Visa,
            MasterCard).
          </p>
          <p>
            Vous devez uniquement entrer vos informations de paiement une seule fois pour acheter plusieurs
            expériences. Vous ne serez débité que lorsque vous achetez une expérience.
            C'est tout ! Il n'y a pas de frais d'inscription ni de frais cachés. Vous ne payez
            que pour ce que vous voulez voir.
          </p>
        </>
      ),
    },
    [index++]: {
      question: <h2>Comment puis-je supprimer mon compte et mes données ?</h2>,
      answer: (
        <p>
          Après vous être connecté, ouvrez la barre latérale utilisateur et allez dans Profil puis
          sélectionnez "Supprimer le compte" et confirmez votre souhait de procéder deux fois.
        </p>
      ),
    },
    
    
  },
};
