import React from "react";
let index = 0;

export const terms = {
  title: "Conditions d'utilisation",
	intro: (
		<>
			<p>
				NOMADS fournit une plateforme web personnalisée permettant à nos membres d'accéder à des expériences ("contenu NOMADS") diffusées sur Internet
				vers certains casques VR, téléphones mobiles, ordinateurs et autres appareils connectés à Internet ("appareils compatibles NOMADS").
			</p>
			<p>
				Ces Conditions d'utilisation régissent l'utilisation de nos services. Toute mention de "service NOMADS", "notre service" ou "le service" dans le
				document suivant fait référence au service personnalisé fourni par NOMADS pour découvrir et regarder du contenu NOMADS, y compris toutes les
				fonctionnalités et options, recommandations et avis, le site web et les interfaces utilisateur, ainsi que tout contenu et logiciel associés à
				nos services.
			</p>
		</>
	),
  sections: {
    [index++]: {
          title: <h2>Achat</h2>,
          content: (
            <p>
              Vous devez sélectionner manuellement les produits que vous souhaitez acheter sur NOMADS. Pour utiliser le service NOMADS, vous devez avoir un accès à Internet et un appareil compatible NOMADS. Pour profiter des expériences NOMADS, vous devrez nous fournir un ou plusieurs moyens de paiement. "Moyen de paiement" désigne un moyen de paiement valide, accepté et actuel, qui peut être mis à jour de temps en temps, et qui peut inclure des paiements tiers via votre compte.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Facturation et Annulation</h2>,
          content: (
            <>
              <p>
                2.1. Moyens de paiement. Pour utiliser les services de NOMADS, vous devez fournir un ou plusieurs moyens de paiement. Vous pouvez mettre à jour vos moyens de paiement en accédant à la page "Compte". Nous pouvons également mettre à jour vos moyens de paiement en utilisant les informations fournies par les prestataires de services de paiement. Après toute mise à jour, vous nous autorisez à continuer de facturer les frais sur les moyens de paiement applicables. Si votre moyen de paiement principal est refusé ou n'est plus disponible pour le paiement, vous nous autorisez à facturer les frais de votre achat sur tout moyen de paiement associé à votre compte. Vous restez responsable des montants non collectés. Si un paiement échoue, en raison de l'expiration, de fonds insuffisants ou d'autres raisons et que vous ne résiliez pas votre compte, nous pouvons suspendre votre accès au service jusqu'à ce que nous ayons réussi à facturer un moyen de paiement valide. Pour certains moyens de paiement, l'émetteur peut vous facturer certains frais, tels que des frais de transaction internationale ou d'autres frais liés au traitement de votre moyen de paiement. Les frais fiscaux locaux peuvent varier en fonction du moyen de paiement utilisé. Vérifiez auprès de votre prestataire de services de paiement pour plus de détails.
              </p>
              <p>
                2.2. Annulation. Vous pouvez annuler votre achat sur NOMADS dans les 14 jours pour les achats que vous n'avez pas commencés à diffuser ou à télécharger. Après 14 jours, ou dès que vous avez commencé à diffuser ou à télécharger votre expérience NOMADS, votre achat est définitif. Vous continuerez d'avoir accès au service NOMADS ainsi qu'à toutes les autres expériences que vous avez précédemment achetées et dont la validité est toujours en cours. Pour annuler votre commande, vous devez nous contacter par email à support@nomads.live et mentionner votre nom, prénom et l'adresse email associée au compte sur lequel vous avez effectué l'achat, ainsi que le titre de l'expérience que vous souhaitez annuler. Après vérification, le remboursement sera effectué via le même moyen de paiement utilisé pour l'achat initial.
              </p>
              <p>
                2.3. Changement de prix. Les prix de toute expérience peuvent changer à tout moment, et NOMADS ne fournit aucune protection contre les variations de prix ni de remboursement en cas de réduction de prix ou d'offre promotionnelle. Vous acceptez de payer pour toute expérience que vous commandez. NOMADS facturera votre carte de crédit ou tout autre moyen de paiement pour le prix indiqué dans l'offre de service payant concernée, ainsi que pour tout montant supplémentaire lié aux taxes applicables, aux frais bancaires et aux fluctuations de la devise.
              </p>
            </>
          ),
        },
        [index++]: {
          title: (
            <h2>
              Capacité de sécurité et politique de transmission des détails de carte de paiement
            </h2>
          ),
          content: (
            <p>
              La protection de vos informations personnelles est une priorité. Nous utilisons Bambora pour fournir un jeton de session sécurisé (SST) afin de générer une page de paiement où votre transaction est traitée de manière sécurisée. La technologie de chiffrement SSL (Secure Sockets Layer) est utilisée pour crypter vos informations avant qu'elles ne soient envoyées.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Service NOMADS</h2>,
          content: (
            <>
              <p>
                3.1. Vous devez avoir 18 ans ou l'âge de la majorité dans votre
                province, territoire ou pays, pour effectuer des achats sur la
                plateforme NOMADS. Les mineurs ne peuvent utiliser le service que sous
                la supervision d'un adulte.
              </p>
              <p>
                3.2. Les services de NOMADS et tout contenu visionné via le service
                sont réservés à un usage personnel et non commercial et ne peuvent pas
                être partagés avec des personnes en dehors de votre foyer. Pendant la
                période de validité de l'accès à vos achats sur NOMADS, nous vous
                accordons un droit limité, non exclusif et non transférable d'accéder
                au service NOMADS et de visionner le contenu NOMADS. À l'exception de ce
                qui précède, aucun droit, titre ou intérêt ne vous sera transféré. Vous
                vous engagez à ne pas utiliser le service pour des performances publiques.
              </p>
              <p>
                3.3. Vous pouvez visionner le contenu NOMADS principalement dans le
                pays où vous avez créé votre compte et uniquement dans les lieux géographiques
                où nous offrons nos services et avons obtenu la licence pour diffuser
                ce contenu. Le contenu disponible à la visualisation peut varier en
                fonction de la localisation géographique et changera de temps en temps.
                Vous ne pouvez utiliser qu'un appareil à la fois pour regarder le contenu
                NOMADS.
              </p>
              <p>
                3.4. Vous vous engagez à utiliser les services NOMADS, y compris toutes
                les fonctionnalités et fonctionnalités associées, conformément à toutes
                les lois, règles et règlements applicables, ou autres restrictions sur
                l'utilisation du service ou du contenu. Vous vous engagez à ne pas
                archiver, reproduire, distribuer, modifier, afficher, exécuter, publier,
                concéder des licences, créer des œuvres dérivées, proposer à la vente
                ou utiliser (sauf si explicitement autorisé dans ces conditions
                d'utilisation) le contenu et les informations contenues sur ou obtenues
                à partir du service NOMADS. Vous vous engagez également à ne pas :
                contourner, supprimer, modifier, désactiver, dégrader ou entraver
                toute protection de contenu dans le service NOMADS ; utiliser un robot,
                une araignée, un extracteur ou tout autre moyen automatisé pour accéder
                au service NOMADS ; décompiler, rétroconcevoir ou désassembler tout
                logiciel ou produit accessible via le service NOMADS ; insérer tout code
                ou produit ou manipuler le contenu du service NOMADS de quelque manière
                que ce soit ; ou utiliser toute méthode d'extraction de données. En
                outre, vous vous engagez à ne pas télécharger, publier, envoyer par
                e-mail ou autrement envoyer ou transmettre tout matériel conçu pour
                interrompre, détruire ou limiter la fonctionnalité de tout logiciel ou
                matériel informatique ou équipement de télécommunications associé au
                service NOMADS, y compris tout virus informatique ou tout autre code,
                fichier ou programme informatique. Nous pouvons résilier ou restreindre
                votre utilisation de notre service si vous violez ces conditions
                d'utilisation ou si vous vous engagez dans une utilisation illégale ou
                frauduleuse du service.
              </p>
              <p>
                3.5. La qualité de l'affichage du contenu NOMADS peut varier d'un
                appareil à l'autre et peut être affectée par divers facteurs, tels
                que votre emplacement, la bande passante disponible et/ou la vitesse
                de votre connexion Internet. La disponibilité de la HD, de l'Ultra HD
                et du HDR dépend de votre fournisseur d'accès Internet et des
                capacités de votre appareil. Tout le contenu n'est pas disponible dans
                tous les formats, tels que la HD, l'Ultra HD ou le HDR, et tous les
                forfaits d'abonnement ne vous permettent pas de recevoir du contenu dans
                tous les formats. Les paramètres de lecture par défaut sur les réseaux
                cellulaires excluent le contenu en HD, Ultra HD et HDR. La vitesse
                minimale de connexion pour la qualité SD est de 0,5 Mbps ; cependant,
                nous recommandons une connexion plus rapide pour améliorer la qualité
                vidéo. Une vitesse de téléchargement d'au moins 5,0 Mbps par flux est
                recommandée pour recevoir du contenu en HD (défini comme une résolution
                de 720p ou plus). Une vitesse de téléchargement d'au moins 25,0 Mbps
                par flux est recommandée pour recevoir du contenu Ultra HD (défini
                comme une résolution de 1080p ou plus) et du contenu HDR. Vous êtes
                responsable de tous les frais d'accès à Internet. Veuillez vérifier
                auprès de votre fournisseur d'accès à Internet pour obtenir des
                informations sur les éventuels frais d'utilisation des données Internet.
                Le temps qu'il faut pour commencer à regarder le contenu NOMADS peut
                varier en fonction de plusieurs facteurs, tels que votre emplacement,
                la bande passante disponible au moment donné, le contenu que vous avez
                sélectionné et la configuration de votre appareil compatible NOMADS.
              </p>
            </>
          ),
        },
        [index++]: {
          title: <h2>Matériel compatible</h2>,
          content: (
            <p>
              Le logiciel NOMADS est développé par, ou pour, NOMADS et est conçu pour
              permettre la visualisation du contenu NOMADS via des appareils compatibles
              NOMADS. Ce logiciel peut varier en fonction de l'appareil et du support,
              et les fonctionnalités et caractéristiques peuvent également différer
              entre les appareils. Vous reconnaissez que l'utilisation du service peut
              nécessiter des logiciels tiers soumis à des licences de tiers. Vous
              acceptez que vous puissiez recevoir automatiquement des versions mises à
              jour du logiciel NOMADS et des logiciels tiers associés.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Mots de passe et accès au compte</h2>,
          content: (
            <p>
              Le membre ayant créé le compte NOMADS et dont la méthode de paiement est
              facturée (le "Propriétaire du compte") a accès et contrôle sur le compte
              NOMADS et les appareils compatibles NOMADS utilisés pour accéder à notre
              service et est responsable de toute activité survenant via le compte
              NOMADS. Pour maintenir le contrôle sur le compte et éviter que
              quelqu'un d'autre y accède (y compris les informations sur l'historique de
              visionnage du compte), le Propriétaire du compte doit garder le contrôle
              sur les appareils compatibles NOMADS utilisés pour accéder au service et ne
              pas divulguer le mot de passe ou les détails de la méthode de paiement
              associée au compte à quiconque. Vous êtes responsable de la mise à jour et
              de l'exactitude des informations que vous nous fournissez concernant votre
              compte. Nous pouvons résilier votre compte ou le mettre en pause afin de
              vous protéger, ainsi que NOMADS ou nos partenaires, contre le vol d'identité
              ou toute autre activité frauduleuse.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Garanties et limitations de responsabilité</h2>,
          content: (
            <p>
              Le service NOMADS est fourni "tel quel" et sans garantie ou condition. En
              particulier, nos services peuvent ne pas être ininterrompus ou exempts
              d'erreurs. Vous renoncez à tous les dommages spéciaux, indirects et
              consécutifs à notre encontre. Ces conditions ne limiteront pas les
              garanties non annulables ou les droits de protection des consommateurs dont
              vous pourriez bénéficier en vertu des lois de votre pays de résidence.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Renonciation à l'action collective</h2>,
          content: (
            <p>
              LÀ OÙ LA LOI APPLICABLE LE PERMET, VOUS ET NOMADS CONVENEZ QUE CHACUN
              PEUT INTENTER DES RECOURS CONTRE L'AUTRE UNIQUEMENT EN VOTRE QUALITÉ
              INDIVIDUELLE, ET NON EN TANT QUE DEMANDEUR OU MEMBRE D'UNE ACTION COLLECTIVE
              OU REPRÉSENTATIVE DANS UNE PROCÉDURE PRÉTENDUE. De plus, là où la loi
              applicable le permet, à moins que vous et NOMADS n'en conveniez autrement,
              le tribunal ne peut pas consolider les demandes de plus d'une personne avec
              vos demandes, et ne peut pas autrement présider une forme quelconque de
              procédure représentative ou collective.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Juridiction</h2>,
          content: (
            <p>
              Le présent accord sera régi et interprété conformément aux lois de la
              province de Québec et aux lois fédérales du Canada, selon le cas, et vous
              vous soumettez à la juridiction exclusive des tribunaux de la province de
              Québec.
            </p>
          ),
        },
        [index++]: {
          title: <h2>Divers</h2>,
          content: (
            <>
              <p>
                8.1. Loi applicable. Ces conditions d'utilisation seront régies et
                interprétées conformément aux lois des Pays-Bas.
              </p>
              <p>
                8.2. Matériel non sollicité. NOMADS n'accepte pas de matériel ou d'idées
                non sollicités pour le contenu de NOMADS et n'est pas responsable de la
                similarité de son contenu ou de sa programmation dans tous les supports
                avec des matériaux ou des idées transmises à NOMADS.
              </p>
              <p>
                8.3. Support client. Pour obtenir plus d'informations sur notre service
                et ses fonctionnalités ou si vous avez besoin d'aide avec votre compte,
                veuillez consulter la FAQ de NOMADS sur notre site Web. En cas de
                conflit entre ces conditions d'utilisation et les informations fournies
                par le support client ou d'autres sections de notre site Web, ces
                conditions d'utilisation prévaudront.
              </p>
              <p>
                8.4. Survie. Si une disposition ou des dispositions de ces conditions
                d'utilisation sont jugées invalides, illégales ou inapplicables, la
                validité, la légalité et l'applicabilité des autres dispositions resteront
                en vigueur et pleinement applicables.
              </p>
              <p>
                8.5. Modifications des conditions d'utilisation et cession. NOMADS peut
                de temps en temps modifier ces conditions d'utilisation. Nous vous
                notifierons au moins 30 jours avant que de telles modifications ne s'appliquent
                à vous. Nous pouvons céder ou transférer notre accord avec vous à tout
                moment, y compris nos droits et obligations associés, et vous acceptez
                de coopérer avec nous dans le cadre de cette cession ou de ce transfert.
              </p>
              <p>
                8.6. Communications électroniques. Nous vous enverrons des informations
                relatives à votre compte (par exemple, autorisations de paiement, factures,
                changements de mot de passe ou de méthode de paiement, messages de confirmation,
                avis) uniquement sous forme électronique, par exemple par e-mail à
                l'adresse e-mail fournie lors de votre inscription.
              </p>
            </>
          ),
        },
        
  },
};
