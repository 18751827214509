import React from "react";
let index = 0;

export const terms = {
  title: "Terms",
  intro: (
    <>
      <p>
        NOMADS provides a personalized web platform that allows our members to
        access experiences ("NOMADS content") streamed over the Internet to
        certain VR headsets, mobile phones, computers and other devices
        connected to Internet("NOMADS ready devices").
      </p>
      <p>
        These Terms of Use govern your use of our services. Any mention of
        "NOMADS service", "our service" or "the service" in the following
        document refers to the personalized service provided by NOMADS for
        discovering and watching NOMADS content, including all features and
        functionalities, recommendations and reviews, the website and user
        interfaces, as well as all content and software associated with our
        services.
      </p>
    </>
  ),
  sections: {
    [index++]: {
      title: <h2>Purchase</h2>,
      content: (
        <p>
          You must manually select the products you want to buy on NOMADS. To
          use the NOMADS service you must have Internet access and a NOMADS
          ready device. To enjoy NOMADS experiences, you will have to provide us
          with one or more Payment Methods. "Payment Method" refers to a
          current, valid, accepted method of payment, which may be updated from
          time to time, and which may include third party payment through your
          account.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Billing and Cancellation</h2>,
      content: (
        <>
          <p>
            2.1. Payment Methods. To use NOMADS services you must provide one or
            more Payment Methods. You can update your Payment Methods by going
            to the "Account" page. We may also update your Payment Methods using
            information provided by the payment service providers. Following any
            update, you authorize us to continue charging fees to the applicable
            Payment Method(s). In the event that your primary Payment Method is
            declined or no longer available to us for payment, you authorize us
            to charge the fees of your purchase to any Payment Method associated
            to your account . You remain responsible for any uncollected
            amounts. If a payment is not successfully settled, due to
            expiration, insufficient funds or otherwise and you do not cancel
            your account, we may suspend your access to the service until we
            have successfully charged a valid Payment Method. For some Payment
            Methods, the issuer may charge you certain fees, such as foreign
            transaction fees or other fees relating to the processing of your
            Payment Method. Local tax charges may vary depending on the Payment
            Method used. Check with your Payment Method service provider for
            details.
          </p>
          <p>
            2.2. Cancellation. You can cancel your NOMADS purchase within 14
            days for purchases you haven't started streaming or downloading.
            After 14 days, or once you have started streaming or downloading
            your NOMADS’ experience, your purchase is final. You will continue
            to have access to the NOMADS service as well as all the other
            experiences that you have previously purchased and whose validity is
            still effective. To cancel your order, you must contact us by email
            at support@nomads.live and mention your last name, first name, and
            email address associated to the account on which you made a
            purchase, as well as the title of the experience you wish to cancel.
            After verification, the refund will be made via the same payment
            method used for the initial purchase.
          </p>
          <p>
            2.3. Changes to the price. Prices for any experience may change at
            any time, and NOMADS does not provide price protection or refunds in
            the event of a price reduction or promotional offering. You agree to
            pay for any experience that you order. NOMADS will charge your
            credit card or other form of payment for the price listed on the
            relevant Paid Service offer, along with any additional amounts
            relating to applicable taxes, bank fees and currency fluctuations.
          </p>
        </>
      ),
    },
    [index++]: {
      title: (
        <h2>
          Security capabilities and policy for transmission of payment card
          details
        </h2>
      ),
      content: (
        <p>
          Protecting your personal information is a priority. We use Bambora to
          provide a Secure Session Token (SST) to generate a payment page where
          your transaction is securely processed. Secure Sockets Layer (SSL)
          technology is used to encrypt your information before it is sent out.
        </p>
      ),
    },
    [index++]: {
      title: <h2>NOMADS Service</h2>,
      content: (
        <>
          <p>
            3.1. You must be 18 years of age, or the age of majority in your
            province, territory or country, to make purchases on the NOMADS
            platform. Minors may only use the service under the supervision of
            an adult.
          </p>
          <p>
            3.2. NOMADS services and any content viewed through the service are
            for your personal and non-commercial use only and may not be shared
            with individuals beyond your household. During the validity period
            of access to your purchases on NOMADS, we grant you a limited,
            non-exclusive, non-transferable right to access the NOMADS service
            and view NOMADS content. Except for the foregoing, no right, title
            or interest shall be transferred to you. You agree not to use the
            service for public performances.
          </p>
          <p>
            3.3. You may view the NOMADS content primarily within the country in
            which you have established your account and only in geographic
            locations where we offer our services and have licensed such
            content. The content available fro viewing may vary according to
            geographic location and will change from time to time. You can only
            use one device at a time to watch NOMADS content.
          </p>
          <p>
            3.4. You agree to use NOMADS services, including all features and
            functionalities associated therewith, in accordance with all
            applicable laws, rules and regulations, or other restrictions on use
            of the service or content therein. You agree not to archive,
            reproduce, distribute, modify, display, perform, publish, license,
            create derivative works from, offer for sale, or use (except as
            explicitly authorized in these Terms of Use) content and information
            contained on or obtained from or through the NOMADS service. You
            also agree not to: circumvent, remove, alter, deactivate, degrade or
            thwart any of the content protections in the NOMADS service; use any
            robot, spider, scraper or other automated means to access the NOMADS
            service; decompile, reverse engineer or disassemble any software or
            other products or processes accessible through the NOMADS service;
            insert any code or product or manipulate the content of the NOMADS
            service in any way; or use any data mining, data gathering or
            extraction method. In addition, you agree not to upload, post,
            e-mail or otherwise send or transmit any material designed to
            interrupt, destroy or limit the functionality of any computer
            software or hardware or telecommunications equipment associated with
            the NOMADS service, including any software viruses or any other
            computer code, files or programs. We may terminate or restrict your
            use of our service if you violate these Terms of Use or are engaged
            in illegal or fraudulent use of the service.
          </p>
          <p>
            3.5. The quality of the display of the NOMADS content may vary from
            device to device, and may be affected by a variety of factors, such
            as your location, the bandwidth available through and/or speed of
            your Internet connection. HD, Ultra HD and HDR availability is
            subject to your Internet service and device capabilities. Not all
            content is available in all formats, such as HD, Ultra HD or HDR and
            not all subscription plans allow you to receive content in all
            formats. Default playback settings on cellular networks exclude HD,
            Ultra HD and HDR content. The minimum connection speed for SD
            quality is 0.5 Mbps; however, we recommend a faster connection for
            improved video quality. A download speed of at least 5.0 Mbps per
            stream is recommended to receive HD content (defined as a resolution
            of 720p or higher). A download speed of at least 25.0 Mbps per
            stream is recommended to receive Ultra HD (defined as a resolution
            of 1080p or higher) and HDR content. You are responsible for all
            Internet access charges. Please check with your Internet provider
            for information on possible Internet data usage charges. The time it
            takes to begin watching NOMADS content will vary based on a number
            of factors, including your location, available bandwidth at the
            time, the content you have selected and the configuration of your
            NOMADS ready device.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Compatible hardware</h2>,
      content: (
        <p>
          NOMADS software is developed by, or for, NOMADS and is designed to
          enable viewing of NOMADS content through NOMADS ready devices. This
          software may vary by device and medium, and functionalities and
          features may also differ between devices. You acknowledge that the use
          of the service may require third party software that is subject to
          third party licenses. You agree that you may automatically receive
          updated versions of the NOMADS software and related third-party
          software.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Passwords and Account Access</h2>,
      content: (
        <p>
          The member who created the NOMADS account and whose Payment Method is
          charged (the "Account Owner") has access and control over the NOMADS
          account and the NOMADS ready devices that are used to access our
          service and is responsible for any activity that occurs through the
          NOMADS account. To maintain control over the account and to prevent
          anyone from accessing the account (which would include information on
          viewing history for the account), the Account Owner should maintain
          control over the NOMADS ready devices that are used to access the
          service and not reveal the password or details of the Payment Method
          associated with the account to anyone. You are responsible for
          updating and maintaining the accuracy of the information you provide
          to us relating to your account. We can terminate your account or place
          your account on hold in order to protect you, NOMADS or our partners
          from identity theft or other fraudulent activity.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Warranties and Limitations on Liability</h2>,
      content: (
        <p>
          The NOMADS service is provided "as is" and without warranty or
          condition. In particular, our services may not be uninterrupted or
          error-free. You waive all special, indirect and consequential damages
          against us. These terms will not limit any non-waivable warranties or
          consumer protection rights that you may be entitled to under the laws
          of your country of residence.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Class Action Waiver</h2>,
      content: (
        <p>
          WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU AND NOMADS AGREE THAT
          EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
          CLASS OR REPRESENTATIVE PROCEEDING. Further, where permitted under the
          applicable law, unless both you and NOMADS agree otherwise, the court
          may not consolidate more than one person's claims with your claims,
          and may not otherwise preside over any form of a representative or
          class proceeding.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Jurisdiction</h2>,
      content: (
        <p>
          This agreement shall be governed by and construed in accordance with
          the laws of the province of Quebec and the federal laws of Canada as
          applicable therein, and you attorn to the exclusive jurisdiction of
          the courts of the province of Quebec.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Miscellaneous</h2>,
      content: (
        <>
          <p>
            8.1. Governing Law. These Terms of Use shall be governed by and
            construed in accordance with the laws of the Netherlands.
          </p>
          <p>
            8.2. Unsolicited Materials. NOMADS does not accept unsolicited
            materials or ideas for NOMADS content and is not responsible for the
            similarity of any of its content or programming in any media to
            materials or ideas transmitted to NOMADS.
          </p>
          <p>
            8.3. Customer Support. To find more information about our service
            and its features or if you need assistance with your account, please
            visit the FAQ of NOMADS on our website. In the event of any conflict
            between these Terms of Use and information provided by Customer
            Support or other portions of our website, these Terms of Use will
            control.
          </p>
          <p>
            8.4. Survival. If any provision or provisions of these Terms of Use
            shall be held to be invalid, illegal, or unenforceable, the
            validity, legality and enforceability of the remaining provisions
            shall remain in full force and effect.
          </p>
          <p>
            8.5. Changes to Terms of Use and Assignment. NOMADS may, from time
            to time, change these Terms of Use. We will notify you at least 30
            days before such changes apply to you. We may surrender or transfer
            our agreement with you at any time, including our associated rights
            and obligations, and you agree to cooperate with us in connection
            with such an assignment or transfer.
          </p>
          <p>
            8.6. Electronic Communications. We will send you information
            relating to your account (e.g. payment authorizations, invoices,
            changes in password or Payment Method, confirmation messages,
            notices) in electronic form only, for example via emails to your
            email address provided during registration.
          </p>
        </>
      ),
    },
  },
};
