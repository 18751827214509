import React from "react";
let index = 0;

export const privacy = {
	title: "Confidentialité",
	intro: "La politique de confidentialité suivante explique nos pratiques, y compris vos choix, concernant la collecte, l'utilisation et la divulgation de certaines informations, y compris vos informations personnelles, par NOMADS ('NOMADS').",
	cookiesSettings: "Options des cookies",

	sections: {
    [index++]: {
      title: <h2>Nous contacter</h2>,
      content: (
        <p>
          Si vous avez des questions générales concernant votre compte ou sur la façon de contacter notre service clientèle pour obtenir de l'aide, veuillez consulter notre FAQ. Pour toute question spécifiquement concernant cette déclaration de confidentialité ou notre utilisation de vos informations personnelles, cookies ou technologies similaires, veuillez contacter notre Responsable de la Protection des Données / Bureau de la Confidentialité par email à privacy@nomads.live. Le responsable du traitement de vos informations personnelles est NOMADS. Veuillez noter que si vous nous contactez pour obtenir de l'aide, pour votre sécurité et la nôtre, nous pourrions avoir besoin d'authentifier votre identité avant de répondre à votre demande.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Collecte d'informations</h2>,
      content: (
        <>
          <p>Nous recevons et stockons des informations vous concernant, telles que :</p>
          <p>
            Informations que vous nous fournissez : Nous collectons les informations que vous nous fournissez, notamment :
          </p>
          <p>
            votre nom de famille, prénom, adresse e-mail, mode(s) de paiement et numéro de téléphone. Nous collectons ces informations de plusieurs manières, notamment lorsque vous les entrez lors de l'utilisation de notre service, lorsque vous interagissez avec notre service clientèle ou participez à des enquêtes ou promotions marketing ;
          </p>
          <p>
            les informations que vous choisissez de fournir, telles que les avis ou évaluations, préférences de goût, paramètres de compte (y compris les préférences définies dans la section "Compte" de notre site Web), ou toute autre information que vous nous fournissez via notre service ou ailleurs.
          </p>
          <p>
            Informations que nous collectons automatiquement : Nous collectons des informations concernant votre utilisation de notre service, vos interactions avec nous, ainsi que des informations concernant votre ordinateur ou tout autre appareil utilisé pour accéder à notre service (tels que les systèmes de jeux, les téléviseurs intelligents, les appareils mobiles et les casques de réalité virtuelle). Ces informations comprennent :
          </p>
          <p>
            votre activité sur le service NOMADS, comme les sélections de titres, l'historique de visionnage et les requêtes de recherche ;
          </p>
          <p>vos interactions avec nos e-mails ;</p>
          <p>
            les détails de vos interactions avec le service clientèle, tels que la date, l'heure et le motif de votre contact, ainsi qu'une copie des e-mails échangés ;
          </p>
          <p>les identifiants d'appareil ou autres identifiants uniques ;</p>
          <p>
            les identifiants publicitaires, tels que ceux sur les appareils mobiles, tablettes et appareils de diffusion en continu qui incluent de tels identifiants (voir la section "Cookies et publicité en ligne" ci-dessous pour plus d'informations) ;
          </p>
          <p>
            les caractéristiques de l'appareil et du logiciel (telles que le type et la configuration), les informations de connexion, les statistiques sur les vues de pages, la source de renvoi (par exemple, les URLs de référence), l'adresse IP (qui peut nous indiquer votre emplacement général), le navigateur et les informations des journaux de serveur web standards ;
          </p>
          <p>
            les informations collectées par le biais de l'utilisation de cookies, balises web et autres technologies, y compris les données publicitaires (telles que les informations sur la disponibilité et la diffusion des publicités, l'URL du site, ainsi que la date et l'heure). (Voir notre section "Cookies et publicité en ligne" pour plus de détails.)
          </p>
          <p>
            Informations provenant d'autres sources : Nous obtenons également des informations d'autres sources. Nous protégeons ces informations conformément aux pratiques décrites dans cette Déclaration de Confidentialité, ainsi qu'aux restrictions supplémentaires imposées par la source des données. Ces sources varient au fil du temps, mais peuvent inclure :
          </p>
          <p>
            des prestataires de services qui nous aident à déterminer une localisation basée sur votre adresse IP afin de personnaliser notre service et à d'autres fins compatibles avec cette Déclaration de Confidentialité ;
          </p>
          <p>
            des partenaires sélectionnés qui rendent notre service disponible sur leurs appareils ou avec lesquels nous proposons des services co-marqués ou menons des activités de marketing conjoint ;
          </p>
          <p>
            des prestataires de services de paiement qui nous fournissent des informations actualisées sur vos moyens de paiement en fonction de leur relation avec vous ;
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Utilisation des Informations</h2>,
      content: (
        <>
          <p>
            Nous utilisons les informations pour fournir, analyser, administrer, améliorer et personnaliser nos services et efforts de marketing, pour traiter votre inscription, vos commandes et vos paiements, et pour communiquer avec vous à ce sujet et sur d'autres sujets. Par exemple, nous utilisons les informations pour :
          </p>
          <p>
            déterminer votre emplacement géographique général, fournir du contenu localisé, vous offrir des recommandations de contenu personnalisées et adaptées à vos intérêts, déterminer votre fournisseur d'accès Internet, et nous aider à répondre rapidement et efficacement aux demandes et demandes de renseignements ;
          </p>
          <p>
            prévenir, détecter et enquêter sur des activités potentiellement interdites ou illégales, y compris la fraude, et faire respecter nos conditions (comme déterminer l'éligibilité à l'essai gratuit) ;
          </p>
          <p>
            analyser et comprendre notre public, améliorer notre service (y compris nos expériences d'interface utilisateur) et optimiser la sélection de contenu, les algorithmes de recommandation et la diffusion ;
          </p>
          <p>
            communiquer avec vous concernant notre service (par exemple par e-mail), afin de vous envoyer des nouvelles concernant NOMADS, des détails sur les nouvelles fonctionnalités et contenus disponibles sur NOMADS, ainsi que des offres spéciales, des annonces promotionnelles et des enquêtes auprès des consommateurs, et pour vous aider avec des demandes opérationnelles telles que des demandes de réinitialisation de mot de passe. Veuillez consulter la section "Vos Choix" de cette déclaration de confidentialité pour savoir comment définir ou modifier vos préférences de communication.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Divulgation des Informations</h2>,
      content: (
        <>
          <p>
            Nous divulguons vos informations à certaines fins et à des tiers, comme décrit ci-dessous :
          </p>
          <p>
            NOMADS : Nous partageons vos informations entre les différents départements de NOMADS selon les besoins pour : le traitement et le stockage des données ; vous fournir un accès à nos services ; fournir un support client ; prendre des décisions concernant les améliorations du service ; le développement de contenu ; et à d'autres fins décrites dans la section Utilisation des Informations de cette Déclaration de Confidentialité.
          </p>
          <p>
            Fournisseurs de services : Nous utilisons d'autres entreprises, agents ou prestataires ("Fournisseurs de Services") pour effectuer des services en notre nom ou pour nous assister dans la fourniture de services à vous. Par exemple, nous engageons des Fournisseurs de Services pour fournir des services de marketing, publicité, communications, infrastructure et informatique, personnaliser et optimiser notre service, traiter les transactions par carte de crédit ou autres méthodes de paiement, fournir un service client, recouvrer des créances, analyser et améliorer les données (y compris les données sur les interactions des utilisateurs avec notre service), et traiter et administrer des enquêtes consommateurs. Dans le cadre de la prestation de ces services, ces Fournisseurs de Services peuvent avoir accès à vos informations personnelles ou autres. Nous ne les autorisons pas à utiliser ou divulguer vos informations personnelles sauf dans le cadre de la fourniture de leurs services.
          </p>
          <p>
            Offres promotionnelles : Nous pouvons proposer des promotions ou programmes conjoints qui, pour que vous y participiez, nécessiteront que nous partagions vos informations avec des tiers. Lors de l'exécution de ces promotions, nous pouvons partager votre nom et d'autres informations dans le cadre de l'exécution de l'incitation. Veuillez noter que ces tiers sont responsables de leurs propres pratiques de confidentialité.
          </p>
          <p>
            Protection de NOMADS et des autres : NOMADS et ses Fournisseurs de Services peuvent divulguer et utiliser vos informations personnelles et autres lorsque nous ou eux estimons raisonnablement que cette divulgation est nécessaire pour (a) satisfaire toute loi applicable, réglementation, procédure légale ou demande gouvernementale, (b) faire respecter les conditions d'utilisation applicables, y compris l'enquête sur les violations potentielles de celles-ci, (c) détecter, prévenir ou traiter des activités illégales ou suspectées d'être illégales (y compris la fraude de paiement), des problèmes de sécurité ou techniques, ou (d) protéger contre les dommages aux droits, biens ou à la sécurité de NOMADS, de ses utilisateurs ou du public, comme requis ou autorisé par la loi.
          </p>
          <p>
            Transferts d'affaires : Dans le cadre de toute réorganisation, restructuration, fusion ou vente, ou autre transfert d'actifs, nous transférerons des informations, y compris des informations personnelles, à condition que la partie réceptrice accepte de respecter vos informations personnelles d'une manière conforme à notre Déclaration de Confidentialité.
          </p>
          <p>
            Chaque fois que, dans le cadre du partage d'informations, nous transférons des informations personnelles vers des pays en dehors de l'Espace Économique Européen et d'autres régions disposant de lois sur la protection des données complètes, nous veillerons à ce que les informations soient transférées conformément à cette Déclaration de Confidentialité et comme permis par les lois applicables sur la protection des données.
          </p>
          <p>
            Vous pouvez également choisir de divulguer vos informations de la manière suivante :
          </p>
          <p>
            lors de l'utilisation du service NOMADS, vous aurez l'occasion de publier des avis ou d'autres informations publiquement, et des tiers pourraient utiliser les informations que vous divulguez ;
          </p>
          <p>
            certaines parties de notre service peuvent contenir un outil vous permettant de partager des informations par e-mail, message texte et applications sociales ou autres applications de partage, en utilisant les clients et applications sur votre appareil intelligent ;
          </p>
          <p>
            des plugins sociaux et des technologies similaires vous permettent de partager des informations.
          </p>
          <p>
            Les plugins sociaux et les applications sociales sont opérés par les réseaux sociaux eux-mêmes et sont soumis à leurs conditions d'utilisation et politiques de confidentialité.
          </p>
        </>
      ),
    },
    
    [index++]: {
      title: <h2>Accès au Compte et aux Profils</h2>,
      content: (
        <>
          <p>
            Pour faciliter l'accès à votre compte, vous pouvez utiliser la fonction "Se souvenir de moi sur cet appareil" lorsque vous vous connectez au site Web. Cette fonction utilise une technologie qui nous permet de fournir un accès direct au compte et d'aider à administrer le service NOMADS sans nécessiter de saisie à nouveau du mot de passe ou d'autres informations d'identification lorsque votre navigateur revisite le service.
          </p>
          <p>
            Pour supprimer l'accès à votre compte NOMADS depuis vos appareils : (a) visitez la section "Compte" de notre site Web, choisissez "Se déconnecter" et suivez les instructions pour désactiver vos appareils (notez que la désactivation peut ne pas se produire immédiatement) ou (b) supprimez les paramètres NOMADS de votre appareil (les étapes varient selon l'appareil et l'option n'est pas disponible sur tous les appareils). Lorsque cela est possible, les utilisateurs d'appareils publics ou partagés doivent se déconnecter à la fin de chaque visite. Si vous vendez ou retournez un ordinateur ou un appareil compatible avec NOMADS, vous devez vous déconnecter et désactiver l'appareil avant de le faire. Si vous ne maintenez pas la sécurité de votre mot de passe ou de votre appareil, ou si vous ne vous déconnectez pas ou ne désactivez pas votre appareil, les utilisateurs suivants pourraient être en mesure d'accéder à votre compte, y compris à vos informations personnelles.
          </p>
          <p>
            Si vous partagez ou permettez à d'autres d'accéder à votre compte, ils pourront voir vos informations (y compris dans certains cas des informations personnelles) telles que votre historique de visionnage, vos évaluations, vos avis et vos informations de compte (y compris votre adresse e-mail, le système de paiement choisi ou d'autres informations dans la section "Compte" de notre site Web).
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Vos Choix</h2>,
      content: (
        <p>
          Email. Si vous ne souhaitez plus recevoir certaines communications de notre part par email, il vous suffit d'accéder à l'option "Paramètres de communication" dans la section "Compte" de notre site Web et de décocher ces éléments pour vous désabonner. Alternativement, cliquez sur le lien "se désabonner" dans l'email. Veuillez noter que vous ne pouvez pas vous désabonner des correspondances liées au service de notre part, telles que les messages relatifs à vos transactions de compte. Publicités basées sur les intérêts. Les publicités basées sur les intérêts sont des publicités en ligne adaptées à vos intérêts probables en fonction de votre utilisation de diverses applications et sites Web sur Internet. Si vous utilisez un navigateur, des cookies et des balises Web peuvent être utilisés pour collecter des informations afin de déterminer vos intérêts probables. Si vous utilisez un appareil mobile, une tablette ou un appareil de diffusion de médias qui inclut un identifiant publicitaire, cet identifiant peut être utilisé pour déterminer vos intérêts probables. Pour vos choix concernant les publicités basées sur les intérêts de NOMADS, veuillez consulter la section "Cookies et Publicité sur Internet" (ci-dessous).
        </p>
      ),
    },
    [index++]: {
      title: <h2>Vos Informations et Vos Droits</h2>,
      content: (
        <>
          <p>
            Vous pouvez demander l'accès à vos informations personnelles, ou corriger ou
            mettre à jour les informations personnelles périmées ou inexactes que nous détenons à votre sujet.
          </p>
          <p>
            Vous pouvez facilement faire cela en visitant la section "Compte" de notre
            site Web, où vous avez la possibilité d'accéder et de mettre à jour un large
            éventail d'informations sur votre compte, y compris vos informations de contact,
            vos informations de paiement NOMADS, et diverses informations relatives à votre compte
            (telles que le contenu que vous avez consulté et noté, et vos avis). Vous devez être connecté
            pour accéder à la section "Compte". Vous pouvez également demander que nous supprimions les informations
            personnelles que nous détenons à votre sujet.
          </p>
          <p>
            Pour faire des demandes, ou si vous avez d'autres questions concernant nos
            pratiques en matière de confidentialité, veuillez contacter notre responsable de la protection des données
            / Bureau de la confidentialité à privacy@nomads.live. Nous répondons à toutes les demandes que nous recevons
            de la part de personnes souhaitant exercer leurs droits en matière de protection des données, conformément
            aux lois applicables sur la protection des données. Veuillez également consulter la section "Vos Choix" de cette Déclaration
            de Confidentialité pour des choix supplémentaires concernant vos informations.
          </p>
          <p>
            Nous pouvons rejeter les demandes qui sont déraisonnables ou non requises par la loi,
            y compris celles qui seraient extrêmement impraticables, nécessiteraient un effort technique disproportionné,
            ou pourraient nous exposer à des risques opérationnels tels que la fraude aux essais gratuits. Nous pouvons conserver
            des informations comme requis ou autorisé par les lois et réglementations applicables, y compris pour honorer vos choix,
            à des fins de facturation ou d'archivage, et pour réaliser les objectifs décrits dans cette Déclaration de Confidentialité.
            Nous prenons des mesures raisonnables pour détruire ou anonymiser les informations personnelles
            de manière sécurisée lorsqu'elles ne sont plus requises.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Sécurité</h2>,
      content: (
        <p>
          Nous utilisons des mesures administratives, logiques, physiques et managériales raisonnables
          pour protéger vos informations personnelles contre la perte, le vol, ainsi que l'accès, l'utilisation et la modification non autorisés.
          Ces mesures sont conçues pour offrir un niveau de sécurité approprié aux risques liés au traitement de vos informations personnelles.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Autres Sites Web, Plateformes et Applications</h2>,
      content: (
        <p>
          Le service NOMADS peut être fourni par le biais de fonctionnalités
          (telles que les commandes vocales) opérées par des plateformes tierces, ou contenir
          des liens vers des sites opérés par des tiers dont les politiques concernant la gestion des informations
          peuvent différer des nôtres. Par exemple, vous pouvez accéder au service NOMADS via des plateformes telles que des
          systèmes de jeux, des téléviseurs intelligents, des appareils mobiles, des appareils de réalité virtuelle et
          de nombreux autres appareils connectés à Internet. Ces sites web et plateformes ont des politiques, des
          déclarations de confidentialité, des avis et des conditions d'utilisation séparées et indépendantes, que nous
          vous recommandons de lire attentivement. De plus, vous pouvez rencontrer des applications tierces qui
          interagissent avec le service NOMADS.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Enfants</h2>,
      content: (
        <p>
          Vous devez avoir 18 ans ou plus pour vous abonner au service NOMADS.
          Dans certaines juridictions, l'âge de la majorité peut être plus élevé que 18 ans,
          auquel cas vous devez satisfaire à cet âge pour devenir membre. Bien que les individus de moins de 18 ans
          puissent utiliser le service, ils ne peuvent le faire qu'avec l'implication, la supervision et l'approbation
          d'un parent ou tuteur légal.
        </p>
      ),
    },
    
    [index++]: {
      title: <h2>Modifications de cette Déclaration de Confidentialité</h2>,
      content: (
        <p>
          Nous mettrons à jour cette Déclaration de Confidentialité de temps en temps en réponse aux
          exigences légales, réglementaires ou opérationnelles changeantes. Nous vous informerons de ces
          changements (y compris de leur date d'entrée en vigueur) conformément à la loi. Votre utilisation continue
          des services NOMADS après ces mises à jour constituera une reconnaissance et, le cas échéant, une acceptation
          de ces changements. Si vous ne souhaitez pas reconnaître ou accepter les mises à jour de cette Déclaration de
          Confidentialité, vous pouvez annuler votre utilisation du service NOMADS.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Cookies et Publicité en Ligne</h2>,
      content: (
        <p>
          Nous et nos prestataires de services utilisons des cookies et d'autres technologies (telles que les balises
          web) pour diverses raisons. Par exemple, nous utilisons ces technologies pour faciliter l'accès à nos services
          en vous reconnaissant lorsque vous revenez, pour fournir et analyser nos services. Nous utilisons également
          des cookies pour en savoir plus sur nos utilisateurs et leurs intérêts probables, ainsi que pour diffuser et
          personnaliser la publicité ou le marketing. Nous souhaitons que vous soyez informé de l'utilisation de ces
          technologies, c'est pourquoi cette section explique les types de technologies que nous utilisons, ce qu'elles
          font et vos choix concernant leur utilisation.
        </p>
      ),
    },
    [index++]: {
      title: <h2>Qu'est-ce qu'un cookie ?</h2>,
      content: (
        <>
          <p>
            Les cookies sont de petits fichiers de données qui sont couramment stockés sur votre appareil
            lorsque vous naviguez et utilisez des sites web et des services en ligne. Ils sont largement utilisés pour
            faire fonctionner les sites web, ou les faire fonctionner plus efficacement, ainsi que pour fournir des
            informations de rapport et aider à la personnalisation des services ou de la publicité.
          </p>
          <p>
            Les cookies ne sont pas les seuls types de technologies qui permettent cette fonctionnalité ; nous utilisons
            également d'autres types de technologies similaires. Consultez ci-dessous pour plus d'informations et des exemples.
          </p>
        </>
      ),
    },
    [index++]: {
      title: <h2>Qu'est-ce que les identifiants publicitaires ?</h2>,
      content: (
        <p>
          Les identifiants publicitaires sont similaires aux cookies et se trouvent sur de nombreux appareils mobiles et
          tablettes (par exemple, l'"Identifiant pour les publicitaires" (ou IDFA) sur les appareils Apple iOS et
          l'"Identifiant publicitaire Google" sur les appareils Android), ainsi que sur certains appareils de diffusion
          de médias en streaming. Comme les cookies, les identifiants publicitaires sont utilisés pour rendre la publicité
          en ligne plus pertinente.
        </p>
      ),
    },
    [index++]: {
      title: (
        <h2>
          Comment puis-je exercer mon choix concernant les cookies et les identifiants publicitaires ?
        </h2>
      ),
      content: (
        <>
          <p>
            Pour plus d'informations sur les cookies définis par notre site web, ainsi que sur d'autres types de suivi en ligne
            (y compris la collecte d'informations par des tiers sur vos activités en ligne au fil du temps et sur différents sites
            web ou services en ligne de tiers pour la publicité basée sur les centres d'intérêt), et pour exercer des choix à
            leur sujet, cliquez ici.
          </p>
          <p>
            Pour vous désinscrire des publicités basées sur les centres d'intérêt de NOMADS en lien avec un identifiant publicitaire
            sur un appareil mobile, une tablette ou un appareil de diffusion de médias en streaming, veuillez configurer les
            paramètres appropriés sur votre appareil (généralement trouvés sous "confidentialité" ou "publicité" dans les
            paramètres de votre appareil). Vous pourrez toujours voir des publicités NOMADS sur votre appareil, mais elles ne
            seront pas adaptées à vos centres d'intérêt. NOMADS soutient les programmes d'auto-régulation suivants, qui offrent des
            choix supplémentaires en matière de confidentialité pour la publicité basée sur les centres d'intérêt :
          </p>
          <p>Aux États-Unis : Digital Advertising Alliance (DAA)</p>
          <p>
            En Europe : European Interactive Digital Advertising Alliance (EDAA)
          </p>
          <p>
            Au Canada : Ad Choices : Digital Advertising Alliance of Canada (DAAC) / Choix de Pub : l'Alliance de la publicité
            numérique du Canada (DAAC)
          </p>
          <p>
            À ce jour, nous ne répondons pas aux signaux "do not track" (ne pas suivre) des navigateurs web.
          </p>
          <p>Comment NOMADS utilise-t-il les balises Web et autres technologies ?</p>
          <p>
            Les balises Web (également connues sous le nom de gifs transparents ou balises pixel) fonctionnent souvent en
            conjonction avec les cookies. Nous et nos prestataires de services pouvons les utiliser à des fins similaires à celles
            des cookies, comme comprendre et améliorer l'utilisation de notre service, améliorer la performance du site, surveiller
            le trafic des visiteurs et leurs actions sur notre site, et comprendre les interactions avec notre marketing (y compris
            les emails et publicités en ligne sur des sites tiers). Parce que les balises Web fonctionnent souvent en conjonction avec
            les cookies, dans de nombreux cas, le refus des cookies réduira l'efficacité des balises Web associées à ces cookies.
          </p>
          <p>
            Nous utilisons d'autres technologies similaires aux cookies, telles que le stockage du navigateur et les plugins (par
            exemple, HTML5, IndexedDB et WebSQL). Comme les cookies, certaines de ces technologies peuvent stocker de petites
            quantités de données sur votre appareil. Nous pouvons utiliser ces technologies et d'autres à des fins similaires à celles
            des cookies, telles que l'application de nos conditions, la prévention de la fraude et l'analyse de l'utilisation de
            notre service. Il existe plusieurs façons d'exercer votre choix concernant ces technologies. Par exemple, de nombreux
            navigateurs populaires offrent la possibilité de vider le stockage du navigateur, généralement dans la zone des paramètres
            ou des préférences ; consultez la fonction d'aide ou la zone de support de votre navigateur pour en savoir plus. D'autres
            technologies, telles que le stockage Silverlight, peuvent être effacées à partir de l'application.
          </p>
        </>
      ),
    },
    
  },
};
